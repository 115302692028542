
.offering_callback_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    .less-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    
    .callback-back {
        text-align: left;
        color: #689F38;
        cursor: pointer;
        font-size: 18px;
    }
    .offering-callback-title {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
    }
    .offering-callback-table {
        .rgt-wrapper {
            height: calc(100vh - 230px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.offeringDetailTitle {
    font-weight: bold;
    font-size: 16px;
    color: #0A5074;
}
.offeringDetailSubTitle {
    font-weight: bold;
    font-size: 14px;
    word-break: break-all;
}
.offering-detail-title {
    color: #0A5074;
}