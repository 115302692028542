
.customer_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    
    .rgt-wrapper {
        height: calc(100vh - 230px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .table-fixed > tbody > tr > td, .table-fixed > thead > tr > th {
        vertical-align: middle;
        word-break: break-word;
    }
    .table-fixed > tbody > tr > td {
        background-color: #ffffff;
        .MuiButton-root {
            min-width: 56px;
        }
    }
    .table-fixed {
        border-collapse:separate; 
        border-spacing: 0 0.35em;
        margin-bottom: 0px;
      }
    .totalCustomers {
        font-weight: 700;
        color: #245776;
        font-size: 18px;
        line-height: 24px;
        display: inline-block;
        padding: 10px;
    }
    .customer-filters {
        display: flex;
        .filterbyContent {
            padding-top: 7px;
            font-weight: bold !important;
            color: #245776;
            font-size: 18px;
        }
        .selectField {
            border: 2px solid #8DC73F !important;
            width: 150px;
            margin-left: 20px;
            margin-right: 20px;
            outline: none;
            outline-color: red !important;
        }
        button {
            margin-left: 20px;
        }
    }
}