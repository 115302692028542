
.offering_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.account-table {
    .rgt-wrapper {
        height: calc(100vh - 200px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
}