
.offering_content_main{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    .rgt-wrapper {
        height: calc(100vh - 270px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .table-show-offerings {
        tbody {
            clear: both;
        }
    }
}
.offering-stats-box {
    padding: 16px;
    cursor: pointer;
}
.offering-stats-box-icon-active {
    width: 60px;
    height: 60px;
    background: #689f38;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 100px;
}
.offering-stats-box-icon-effective {
    width: 60px;
    height: 60px;
    background: #2fcee4;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 100px;
    
}
.offering-state-detail {
    border: 1px solid #bfbfbf;
    padding: 10px;
    margin-bottom: 16px;
    .offering-state-detail-row {
        margin-bottom: 10px;
    }
}
.offering-stats-box-icon-cancelled {
    width: 60px;
    height: 60px;
    background: #f1b44f;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 100px;
    
}
.offering-stats-box-icon-closed {
    width: 60px;
    height: 60px;
    background: #fe6e50;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 100px;
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.offeringDetailTitle {
    font-weight: bold;
    font-size: 16px;
    color: #0A5074;
}
.offeringDetailSubTitle {
    font-weight: bold;
    font-size: 14px;
    word-break: break-all;
}
.offering-detail-title {
    color: #0A5074;
}