
.profile_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
    .profile-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
}