
.offering_userbounce{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    
    .less-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
    .communication-content span {
        display: inline-block !important;
        white-space: normal !important;
        font-style: normal !important;
        font-size: 16px !important;
        color: #0A5074 !important;
    }
    .callback-back {
        text-align: left;
        color: #689F38;
        cursor: pointer;
        font-size: 18px;
    }
    .offering-bounceuser-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .callback-content span {
        display: inline-block !important;
        white-space: normal !important;
        font-style: normal !important;
        font-size: 16px !important;
        color: #0A5074 !important;
    }
    .user-bounce-list-table {
        .rgt-wrapper {
            height: 400px;    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}
.bounces_email_body {
    height: 400px;
    overflow: hidden scroll;
    
}
.bounces_prospectus_body {
    height: 400px;
    .prospectus_body_content {
        height: 320px;
        overflow: hidden scroll;
    }
    .prospect_resend_btn {
        background: #8DC73F;
        border-color: #8DC73F;
        color: #fff;
        font-size: 16px;
        border-radius: 10px;
        margin: 20px;
    }
}