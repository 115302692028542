
.offering_investors_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    
    .rgt-wrapper {
        height: calc(100vh - 350px);    
    }
    .rgt-cell-header-inner {
        font-weight: bold;
    }
    .noDataFound {
        padding-top: 25px;
        font-weight: bold !important;
        color: #245776;
        font-size: 18px;
        margin-left: 10px;
        text-align: center;
    }
    .callback-back {
        text-align: left;
        color: #689F38;
        cursor: pointer;
        font-size: 18px;
    }
    .offering-callback-title {
        font-size: 18px;

    }
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .text_center {
        text-align: center;
    }
    .offeringinvestors-top_bar {
        .sub-info {
            font-size: 14px;
            display: inline;
            color: #678899;
        }
        .sub {
            color: #c9c9c9;
            font-size: 14px;
        }
    }
    .td-data {
        padding: 10px;
    }
    .tbl-investors-footer{
        border-top: 1px solid #EBEBEB !important;
    }
    .tbl tr {
        border-left: 1px solid #C8DBAF;
        box-sizing: border-box;
        margin-top: 16px;
        margin-bottom: 16px;
        border-right: 1px solid #C8DBAF;
        // border-top: 1px solid #C8DBAF;

    }
    .tbl td {
        border: none;
        border-bottom: 1px solid #C8DBAF;
    }
    .tbl {
        width: 100%;
        border-collapse: collapse;
    }
    .tbl th {
        background: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-top: none;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        font-size: 16px;
        color: #0A5074;
        cursor: pointer;
    }
    .ta-center {
        text-align: center;
    }
    .ta-left {
        text-align: left;
        a {
          font-weight: bold;
        }
    }
    .ta-right {
        text-align: right;
    }
    td.ta-left {
        font-family: Open Sans,sans-serif !important;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 16px;
        color: #678899;
    }
    td.ta-right {
        font-family: Open Sans,sans-serif !important;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 16px;
        text-align: right;
        color: #678899;
    }
    .tbl td {
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
    }
}
.requested-share-change-input {
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 5px;
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.offeringDetailTitle {
    font-weight: bold;
    font-size: 16px;
    color: #0A5074;
}
.offeringDetailSubTitle {
    font-weight: bold;
    font-size: 14px;
    word-break: break-all;
}
.offering-detail-title {
    color: #0A5074;
}

.requested-amount-edit-title {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}
.requested-amount-edit-subtitle {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    color: #689F38;
}

.warning_message_Editmodal {
    color: red;
    padding: 10px 20px;
}