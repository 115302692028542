.reset_password_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    color: #fff;
    margin-top: 15px;
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
.reset_password_heading::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #fff;
    max-width: 150px;
    margin: 0 auto;
}
.reset_password_inner_content{
    padding: 25px 25px;
    background: gray;
}
.reset_password_content{
    text-align: center;
    font-size: large;
    margin-top: 60px;
    color: #fff;
}
.reset{
    margin-bottom: 5px;
}
.check_inbox{
    margin-bottom: 5px;
}
.resend_mail{
    cursor: pointer;
    display: inline-block;
}
.resend_mail:hover {
    text-decoration: underline;
}
.reset_password_send_content{
    text-align: center;
    font-size: large;
    margin-top: 60px;
    color: #fff
}
.contact_us{
    color: #fff;
}
.contact_us_link{
    cursor: pointer;
    margin-left: 3px;
}
.contact_us_link:hover {
    text-decoration: underline;
}
.form_content_resetpassword{
    position: relative;
    margin-bottom: 45px;
}
.form_content_resetpassword::after{
    /* background-image: url('../../assets/images/lock_icon.png'); */
    content: '';
    position: absolute;
    height: 60px;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: 15px center;
    display: block;
    top: 0;
    left: 0;
    width: 55px;
}
.showhidepasswordbtnReset {
    position: absolute;
    right: 10px;
    top: 18px;
    font-size: 18px;
    color: #689F38;
    cursor: pointer;
    padding: 0px 10px;
}