
.investor_content{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    .green-color-with-pointer {
        color: #689F38;
        cursor: pointer;
    }
    .total-investor-title {
        font-weight: bold;
        font-size: 18px;
    }
    .investors-list-table {
        .rgt-wrapper {
            height: calc(100vh - 200px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}