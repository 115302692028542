
.offering_communication{
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    padding: 1rem;
    
    .less-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
    .callback-content span {
        display: inline-block !important;
        white-space: normal !important;
        font-style: normal !important;
        font-size: 16px !important;
        color: #0A5074 !important;
    }
    .offering-ommunication-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .orderhistory-titles-order {
        color: #8DC73F;
        font-weight: 500;
        font-size: 17px;
        font-weight: bold;
    }
    .orderhistory-timestamp-order {
        color: #000000;
        font-size: 17px;
    }
    
    .communication-content span {
        display: inline-block !important;
        white-space: normal !important;
        font-style: normal !important;
        font-size: 16px !important;
        color: #0A5074 !important;
    }
    
    .offering_communication-back {
        color: #8DC73F;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
    }
    .offering-communication-table {
        .rgt-wrapper {
            height: calc(100vh - 275px);    
        }
        .rgt-cell-header-inner {
            font-weight: bold;
        }
    }
}