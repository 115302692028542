
.forgetpassword_content{
    margin: auto;
}
.forgetpassword_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.form_content_forgetpassword{
    margin-top: 15px;
}
.btn-primary{
    color: #fff;
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
    width: 100%;
    margin-top: 10px;
}
.company_logo{
    height: 75px;
    text-align: center;
}
.form-control:focus{
    box-shadow: none !important;
}

.mt-35px {
    margin-top: 35px;
}
.text-decoration-none {
    text-decoration: none !important;
}
.eye_icon_class{
    position: absolute;
    right: 20px;
    top: 24px;
}
.eye_icon_div{
    position: relative;
}
.return_login {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}